import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DualMeetType, IDualMeetBout, IDualMeetBoutFencer, IDualMeet_DB, IExistingFencer, ITeam } from "../../../types";
import useDatabase from "../../../hooks/database";
import TbTPage from "../../../components/TbTPage/TbTPage";
import "./FencerInfo.css";
import { boutWinner } from "../../../utils/helpers";
import { FencerInfoBoxHighSchool } from "../../../components/FencerInfo/FencerInfo";
import { DBResult, isSuccess } from "../../../utils/database";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Chip, Divider, Dialog, DialogActions, DialogTitle, DialogContent } from "@mui/material";
import Switch from "@mui/material/Switch";
import { mdiFilterVariant } from "@mdi/js";
import Icon from "@mdi/react";
import Button from "@mui/material/Button";
import NotFound from "../NotFound/NotFound";
import { HighSchoolDistricts, HighSchoolConferences } from "../../../utils/ncaaConference";
import { CommonLoading } from "../../../components/Loading/Loading";

interface ITeamWithDistrict extends ITeam {
    district?: string;
}

const FencerInfo = () => {
    const { id } = useParams<{ id: string }>();
    const DB = useDatabase();

    const [loaded, setLoaded] = useState(false);

    const [fencerInfo, setFencerInfo] = useState<IExistingFencer | null>(null);
    const [fencerTeams, setFencerTeams] = useState<Record<string, ITeam> | null>(null);
    const [allRelatedTeams, setAllRelatedTeams] = useState<Record<string, ITeamWithDistrict> | null>(null);
    const [allRelatedFencers, setAllRelatedFencers] = useState<Record<string, IDualMeetBoutFencer[]> | null>(null);
    const [meets, setMeets] = useState<Record<string, IDualMeet_DB> | null>(null);
    const [seasons, setSeasons] = useState<string[]>([]);
    const [conferences, setConferences] = useState<string[]>([]);
    const [districts, setDistricts] = useState<string[]>([]);

    const [sortedMeets, setSortedMeets] = useState<IDualMeet_DB[]>([]);
    const [meetBoutRec, setMeetBoutRec] = useState<Record<string, IDualMeetBout[]>>({});
    const [meetBoutRecDisplay, setMeetBoutRecDisplay] = useState<Record<string, IDualMeetBout[]>>({});

    const [filterModalOpen, setFilterModalOpen] = useState(false);

    const [filterSeason, setFilterSeason] = useState<string | null>(null);
    const [filterTeam, setFilterTeam] = useState<ITeam | null>(null);
    const [filterFencer, setFilterFencer] = useState<IDualMeetBoutFencer | null>(null);
    const [filterConference, setFilterConference] = useState<string | null>(null);
    const [filterDistrict, setFilterDistrict] = useState<string | null>(null);

    const [totalWins, setTotalWins] = useState(0);
    const [totalLosses, setTotalLosses] = useState(0);
    const [totalBouts, setTotalBouts] = useState(0);

    const [filteredTotalBouts, setFilteredTotalBouts] = useState(0);
    const [filteredBoutLosses, setFilteredBoutLosses] = useState(0);
    const [filteredBoutWins, setFilteredBoutWins] = useState(0);

    const [filterByNewest, setFilterByNewest] = useState(true);

    const handleFencerInfo = (result: DBResult<IExistingFencer>) => {
        if (result.status === "fail") return;
        setFencerInfo(result.data);
        return result.data;
    };

    useEffect(() => {
        DB.getFencer(id).then(handleFencerInfo).then(handleFencerTeams);
    }, [id]);

    // Find the current fencer's teams
    const handleFencerTeams = (fencerInfo: IExistingFencer) => {
        if (fencerInfo?.teams) {
            Promise.all(fencerInfo.teams.map(l => DB.getTeam(l)))
                .then(t => {
                    const res: Record<string, ITeam> = {};
                    for (const team of t) {
                        if (team.status === "fail") continue;
                        res[team.data.id] = team.data;
                    }
                    setFencerTeams(res);
                    return res;
                })
                .then(handleMeetsAndSeasons)
                .then(meets => handleBouts(meets, fencerInfo))
                .then(allRelatedFencers => handleTeams(allRelatedFencers, fencerInfo));
        }
    };

    // Find all meets and seasons referenced in the page
    const handleMeetsAndSeasons = async (fencerTeams: Record<string, ITeam>) => {
        const meetIDs: string[] = [];
        const seasonsNew = new Set<string>();
        for (const teamID in fencerTeams) {
            const team = fencerTeams[teamID];
            meetIDs.push(...Object.values(team.dualMeets).flat());
            for (const season of Object.keys(team.dualMeets)) {
                seasonsNew.add(season);
            }
        }

        setSeasons([...seasonsNew]);

        const m = await Promise.all(meetIDs.map(l => DB.getDualMeetRaw(l)));

        const meetsFinal = Object.fromEntries([
            ...m
                .filter(isSuccess)
                .map(l => l.data)
                .filter(l => l.published)
                .filter(l => l.type !== DualMeetType.JV && l.type !== DualMeetType.Scrimmage)
                .map(l => [l.id, l])
        ]);

        setMeets(meetsFinal);

        return meetsFinal;
    };

    // Find all bouts in this page
    const handleBouts = async (meets: Record<string, IDualMeet_DB>, fencerInfo: IExistingFencer) => {
        const boutIDs: string[] = [];

        for (const meet of Object.values(meets)) {
            boutIDs.push(...meet.bouts.map(l => l.id));
        }

        const b = await Promise.allResolved(boutIDs.map(l => DB.getBout(l)));
        const dbBouts = b.filter(isSuccess).map(l => l.data);

        const boutsObj: Record<string, IDualMeetBout[]> = {};
        let won = 0;
        let lost = 0;
        let total = 0;

        const teamToFencers: Record<string, IDualMeetBoutFencer[]> = {};

        for (const bout of dbBouts) {
            if (bout.fencer1.fencerInfo.id !== fencerInfo!.id && bout.fencer2.fencerInfo.id !== fencerInfo!.id) {
                continue;
            }

            boutsObj[bout.dualMeetId] ??= [];
            boutsObj[bout.dualMeetId].push(bout);

            const winner = boutWinner(bout, { team: false });

            if (
                (bout.fencer1.fencerInfo.id === fencerInfo!.id && winner === 1) ||
                (bout.fencer2.fencerInfo.id === fencerInfo!.id && winner === 2)
            ) {
                won++;
            } else if (
                (bout.fencer1.fencerInfo.id === fencerInfo!.id && winner === 2) ||
                (bout.fencer2.fencerInfo.id === fencerInfo!.id && winner === 1)
            ) {
                lost++;
            }
            total++;

            if (bout.fencer1.fencerInfo.id && bout.fencer1.fencerInfo.id !== fencerInfo!.id) {
                const teamId = meets[bout.dualMeetId].team1ID;

                if (teamId) {
                    teamToFencers[teamId] ??= [];

                    // Ensure that each team only has one unknown fencer since we treat them
                    // equally
                    if (!teamToFencers[teamId].find(f => f.fencerInfo.id === bout.fencer1.fencerInfo.id)) {
                        if (
                            !teamToFencers[teamId].find(f => f.fencerInfo.firstName === "Unknown" && f.fencerInfo.lastName === "fencer") ||
                            bout.fencer1.fencerInfo.firstName !== "Unknown"
                        ) {
                            teamToFencers[teamId].push(bout.fencer1);
                        }
                    }
                }
            } else if (bout.fencer2.fencerInfo.id && bout.fencer2.fencerInfo.id !== fencerInfo!.id) {
                const teamId = meets[bout.dualMeetId].team2ID;

                if (teamId) {
                    teamToFencers[teamId] ??= [];

                    // Ensure that each team only has one unknown fencer since we treat them
                    // equally
                    if (!teamToFencers[teamId].find(f => f.fencerInfo.id === bout.fencer2.fencerInfo.id)) {
                        if (
                            !teamToFencers[teamId].find(f => f.fencerInfo.firstName === "Unknown" && f.fencerInfo.lastName === "fencer") ||
                            bout.fencer2.fencerInfo.firstName !== "Unknown"
                        ) {
                            teamToFencers[teamId].push(bout.fencer2);
                        }
                    }
                }
            }
        }

        setMeetBoutRec(boutsObj);
        setAllRelatedFencers(teamToFencers);
        setTotalBouts(total);
        setTotalWins(won);
        setTotalLosses(lost);

        return teamToFencers;
    };

    // Find all teams referenced in the page
    const handleTeams = (allRelatedFencers: Record<string, IDualMeetBoutFencer[]>, fencerInfo: IExistingFencer) => {
        const allTeamIds = new Set(Object.keys(allRelatedFencers));

        // fencerInfo guaranteed to be nonnull (meets is not null, therefore fencerTeams is not null)
        for (const fencerTeam of Object.values(fencerInfo!.teams).flat()) {
            allTeamIds.delete(fencerTeam);
        }

        Promise.all([...allTeamIds].map(l => DB.getTeam(l))).then(async t => {
            const res: Record<string, ITeamWithDistrict> = {};
            const conf = new Set<string>();
            const dist = new Set<string>();
            for (const team of t) {
                if (team.status === "fail") continue;
                const org = await DB.getOrganizationFromTeam(team.data.id);
                res[team.data.id] = { ...team.data, district: isSuccess(org) ? org.data.district : undefined };
                if (isSuccess(org) && org.data.district) {
                    dist.add(org.data.district);
                }
                if (team.data.conference) {
                    // Backwards compatibility
                    if (typeof team.data.conference === "string") {
                        conf.add(team.data.conference);
                    } else {
                        for (const c of team.data.conference) {
                            conf.add(c);
                        }
                    }
                }
            }
            setAllRelatedTeams(res);
            setDistricts([...dist]);
            setConferences([...conf]);
            setLoaded(true);
        });
    };

    // Handles filtering and sorting
    useEffect(() => {
        if (!loaded) {
            return;
        }

        // Meet to list of bouts
        const displayBouts: Record<string, IDualMeetBout[]> = {};

        let total = 0;
        let won = 0;
        let lost = 0;

        for (const meet in meetBoutRec) {
            for (const bout of meetBoutRec[meet]) {
                let opponentTeamID;

                if (meets![meet].team1ID && !Object.values(fencerTeams!).find(t => t.id === meets![meet].team1ID)) {
                    opponentTeamID = meets![meet].team1ID;
                } else {
                    opponentTeamID = meets![meet].team2ID;
                }

                const boutMeet = meets![bout.dualMeetId];
                const opponentTeam = opponentTeamID ? allRelatedTeams![opponentTeamID] : null;

                if (
                    // season
                    (!filterSeason || filterSeason === "All seasons" || filterSeason === boutMeet.season) &&
                    // team
                    (!filterTeam || filterTeam.id === boutMeet.team1ID || filterTeam.id === boutMeet.team2ID) &&
                    // fencer
                    // Unknown fencers should be treated as the same person
                    (!filterFencer ||
                        filterFencer.fencerInfo.id === bout.fencer1.fencerInfo.id ||
                        filterFencer.fencerInfo.id === bout.fencer2.fencerInfo.id ||
                        (filterFencer.fencerInfo.firstName === "Unknown" &&
                            filterFencer.fencerInfo.lastName === "fencer" &&
                            ((bout.fencer1.fencerInfo.firstName === "Unknown" && bout.fencer1.fencerInfo.lastName === "fencer") ||
                                (bout.fencer2.fencerInfo.firstName === "Unknown" && bout.fencer2.fencerInfo.lastName === "fencer")))) &&
                    // conference
                    (!filterConference ||
                        filterConference === "All conferences" ||
                        (opponentTeam && opponentTeam.conference?.includes(filterConference))) &&
                    // district
                    (!filterDistrict || filterDistrict === "All districts" || (opponentTeam && opponentTeam.district === filterDistrict))
                ) {
                    displayBouts[meet] ??= [];
                    displayBouts[meet].push(bout);

                    const winner = boutWinner(bout, { team: false });

                    if (
                        (bout.fencer1.fencerInfo.id === fencerInfo!.id && winner === 1) ||
                        (bout.fencer2.fencerInfo.id === fencerInfo!.id && winner === 2)
                    ) {
                        won++;
                    } else if (
                        (bout.fencer1.fencerInfo.id === fencerInfo!.id && winner === 2) ||
                        (bout.fencer2.fencerInfo.id === fencerInfo!.id && winner === 1)
                    ) {
                        lost++;
                    }
                    total++;
                }
            }
        }

        const sorted = [...Object.values(meets!)];

        if (filterByNewest) {
            sorted.sort((a, b) => b.startedAt - a.startedAt);
        } else {
            sorted.sort((a, b) => a.startedAt - b.startedAt);
        }

        setSortedMeets(sorted);
        setMeetBoutRecDisplay(displayBouts);
        setFilteredTotalBouts(total);
        setFilteredBoutWins(won);
        setFilteredBoutLosses(lost);
    }, [loaded, meetBoutRec, filterByNewest, filterSeason, filterTeam, filterFencer, filterDistrict, filterConference]);

    const handleMeetOrderChange = event => {
        setFilterByNewest(event.target.checked);
    };

    if (!loaded || !fencerTeams || !meets || !allRelatedTeams || !allRelatedFencers) {
        return (
            <TbTPage>
                <CommonLoading />
            </TbTPage>
        );
    } else if (!fencerInfo) {
        return <NotFound message="The requested fencer could not be found." />;
    }

    return (
        <TbTPage className="eventsPage">
            <div style={{ height: 50, margin: "auto" }}></div>

            <h1 style={{ fontWeight: "500", fontFamily: "Lexend Deca" }}>
                {fencerInfo.firstName} {fencerInfo.lastName}
            </h1>
            <h3 style={{ marginBottom: "20px" }}>
                {Object.values(fencerTeams)
                    .map(l => l.name)
                    .join(", ")}
            </h3>

            <div style={{ maxWidth: "700px", margin: "auto" }}>
                <Card>
                    <CardContent style={{ padding: "2rem" }}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexWrap: "wrap"
                            }}
                        >
                            <div
                                style={{
                                    minWidth: 200,
                                    maxWidth: 200
                                }}
                            >
                                <Typography variant="h6" component="div">
                                    Alltime Bouts
                                </Typography>
                                <Typography variant="h5" component="div">
                                    {totalBouts}
                                </Typography>
                            </div>
                            <div
                                style={{
                                    minWidth: 200,
                                    maxWidth: 200
                                }}
                            >
                                <Typography variant="h6" component="div">
                                    Alltime Record
                                </Typography>
                                <Typography variant="h5" component="div">
                                    {totalWins} - {totalLosses}
                                </Typography>
                            </div>
                            <div
                                style={{
                                    minWidth: 200,
                                    maxWidth: 200
                                }}
                            >
                                <Typography variant="h6" component="div">
                                    Alltime Win Rate
                                </Typography>
                                <Typography variant="h5" component="div">
                                    {(totalBouts === 0 ? 0 : (totalWins * 100) / totalBouts).toFixed(1)}%
                                </Typography>
                            </div>
                        </div>

                        <Divider style={{ marginTop: "1rem", marginBottom: "1rem" }} />

                        <Button color="secondary" sx={{ marginBottom: "0.25rem" }} onClick={() => setFilterModalOpen(true)}>
                            <Icon path={mdiFilterVariant} style={{ marginRight: "4px" }} size="20px" />
                            Filter Results (
                            {(filterSeason && filterSeason !== "All seasons" ? 1 : 0) +
                                (filterTeam ? 1 : 0) +
                                (filterFencer ? 1 : 0) +
                                (filterConference ? 1 : 0) +
                                (filterDistrict ? 1 : 0)}{" "}
                            filters applied)
                        </Button>

                        <div style={{ marginBottom: "1rem", display: "flex", gap: "0.5rem", justifyContent: "center", flexWrap: "wrap" }}>
                            {filterSeason && filterSeason !== "All seasons" && (
                                <Chip
                                    label={filterSeason}
                                    variant="outlined"
                                    onDelete={() => setFilterSeason(null)}
                                    onClick={() => setFilterModalOpen(true)}
                                />
                            )}
                            {filterTeam && (
                                <Chip
                                    label={filterTeam.name}
                                    variant="outlined"
                                    onDelete={() => {
                                        setFilterTeam(null);
                                        setFilterFencer(null);
                                    }}
                                    onClick={() => setFilterModalOpen(true)}
                                />
                            )}
                            {filterFencer && (
                                <Chip
                                    label={`${filterFencer.fencerInfo.firstName} ${filterFencer.fencerInfo.lastName}`}
                                    variant="outlined"
                                    onDelete={() => setFilterFencer(null)}
                                    onClick={() => setFilterModalOpen(true)}
                                />
                            )}
                            {filterConference && (
                                <Chip
                                    label={HighSchoolConferences.find(c => c.id === filterConference)?.name ?? filterConference}
                                    variant="outlined"
                                    onDelete={() => setFilterConference(null)}
                                    onClick={() => setFilterModalOpen(true)}
                                />
                            )}
                            {filterDistrict && (
                                <Chip
                                    label={HighSchoolDistricts.find(c => c.id === filterDistrict)?.name ?? filterDistrict}
                                    variant="outlined"
                                    onDelete={() => setFilterDistrict(null)}
                                    onClick={() => setFilterModalOpen(true)}
                                />
                            )}
                        </div>

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexWrap: "wrap"
                            }}
                        >
                            <div
                                style={{
                                    minWidth: 200,
                                    maxWidth: 200
                                }}
                            >
                                <Typography variant="h6" component="div">
                                    Bouts
                                </Typography>
                                <Typography variant="h5" component="div">
                                    {filteredTotalBouts}
                                </Typography>
                            </div>
                            <div
                                style={{
                                    minWidth: 200,
                                    maxWidth: 200
                                }}
                            >
                                <Typography variant="h6" component="div">
                                    Record
                                </Typography>
                                <Typography variant="h5" component="div">
                                    {filteredBoutWins} - {filteredBoutLosses}
                                </Typography>
                            </div>
                            <div
                                style={{
                                    minWidth: 200,
                                    maxWidth: 200
                                }}
                            >
                                <Typography variant="h6" component="div">
                                    Win Rate
                                </Typography>
                                <Typography variant="h5" component="div">
                                    {(filteredBoutWins === 0 ? 0 : (filteredBoutWins * 100) / filteredTotalBouts).toFixed(1)}%
                                </Typography>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </div>

            <FencerInfoBoxHighSchool key={0} meets={sortedMeets} meetBoutRec={meetBoutRecDisplay} id={id} />

            <div style={{ height: 30 }}></div>

            <Dialog open={filterModalOpen} onClose={() => setFilterModalOpen(false)}>
                <DialogTitle>Filters</DialogTitle>
                <DialogContent sx={{ width: "min(80vw, 500px)" }}>
                    <Autocomplete
                        disablePortal
                        options={[...seasons, "All seasons"]}
                        value={filterSeason}
                        renderInput={params => <TextField {...params} label="Filter by season" sx={{ background: "#1e1e1e" }} />}
                        onChange={(_, newValue: string | null) => {
                            setFilterSeason(newValue ?? "");
                        }}
                        sx={{
                            marginBottom: "1rem",
                            marginTop: "1rem"
                        }}
                    />
                    <Autocomplete
                        disablePortal
                        options={[...Object.values(allRelatedTeams), null]}
                        getOptionLabel={option => (option ? option.name : "All teams")}
                        value={filterTeam}
                        renderInput={params => <TextField {...params} label="Filter by school" sx={{ background: "#1e1e1e" }} />}
                        onChange={(_, newValue: ITeam | null) => {
                            setFilterTeam(newValue);
                        }}
                        sx={{
                            marginBottom: "1rem",
                            marginTop: "1rem"
                        }}
                    />
                    {conferences && conferences.length > 0 && (
                        <Autocomplete
                            disablePortal
                            options={[...conferences, "All conferences"]}
                            getOptionLabel={option => HighSchoolConferences.find(c => c.id === option)?.name ?? option}
                            value={filterConference}
                            renderInput={params => <TextField {...params} label="Filter by conference" sx={{ background: "#1e1e1e" }} />}
                            onChange={(_, newValue: string | null) => {
                                setFilterConference(newValue);
                            }}
                            sx={{
                                marginBottom: "1rem",
                                marginTop: "1rem"
                            }}
                        />
                    )}
                    {districts && districts.length > 0 && (
                        <Autocomplete
                            disablePortal
                            options={[...districts, "All districts"]}
                            getOptionLabel={option => HighSchoolDistricts.find(d => d.id === option)?.name ?? option}
                            value={filterDistrict}
                            renderInput={params => <TextField {...params} label="Filter by district" sx={{ background: "#1e1e1e" }} />}
                            onChange={(_, newValue: string | null) => {
                                setFilterDistrict(newValue);
                            }}
                            sx={{
                                marginBottom: "1rem",
                                marginTop: "1rem"
                            }}
                        />
                    )}
                    <Autocomplete
                        disablePortal
                        options={filterTeam ? [...allRelatedFencers[filterTeam.id], null] : []}
                        disabled={!filterTeam}
                        getOptionLabel={option => (option ? `${option.fencerInfo.firstName} ${option.fencerInfo.lastName}` : "All fencers")}
                        value={filterFencer}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={filterTeam ? "Filter by fencer" : "Select team to filter by fencer"}
                                sx={{ background: "#1e1e1e" }}
                            />
                        )}
                        onChange={(_, newValue: IDualMeetBoutFencer | null) => {
                            setFilterFencer(newValue);
                        }}
                    />

                    <div style={{ display: "flex", alignItems: "center", marginTop: "1rem" }}>
                        <Switch checked={filterByNewest} onChange={handleMeetOrderChange} inputProps={{ "aria-label": "controlled" }} />
                        <Typography sx={{ fontSize: 14 }}>Sort by newest meets</Typography>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="text"
                        onClick={() => {
                            setFilterByNewest(true);
                            setFilterFencer(null);
                            setFilterTeam(null);
                            setFilterSeason(null);
                            setFilterConference(null);
                            setFilterDistrict(null);
                        }}
                    >
                        Clear filter
                    </Button>
                    <Button variant="text" onClick={() => setFilterModalOpen(false)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </TbTPage>
    );
};

export default FencerInfo;
