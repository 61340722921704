import { useState, useEffect } from "react";
import { CommonLoading } from "../../../components/Loading/Loading";
import TbTPage from "../../../components/TbTPage/TbTPage";
import { DualMeetType, IDualMeet, IDualMeet_DB, UserFlag } from "../../../types";

import "./DualMeets.css";

import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import DualMeetInfoComponent from "../../../components/DualMeetInfo";
import useDatabase from "../../../hooks/database";
import {
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Switch,
    TextField
} from "@mui/material";
import { userCanSeeMeet } from "../../../utils/helpers";
import { useSelector } from "react-redux";
import { ReduxState } from "../../../utils/store";
import { DBResult } from "../../../utils/database";
import StyledDropdown from "../../../components/StyledDropdown/StyledDropdown";
import { CURRENT_SEASON_STR } from "../../../utils/season";

export default function DualMeets() {
    const DB = useDatabase();
    const userInfo = useSelector((s: ReduxState) => s.userInfo);
    const siteAdmin = useSelector((s: ReduxState) => s.siteAdmin);

    const [viewMs, setViewMs] = useState(false);
    const [viewHs, setViewHs] = useState(true);
    const [viewUnpublished, setViewUnpublished] = useState(true);

    const [dualMeetsRaw, setDualMeetsRaw] = useState<IDualMeet_DB[]>([]);
    const [loading, setLoading] = useState(false);
    const [selectedSeason, setSelectedSeason] = useState(CURRENT_SEASON_STR);
    const [meetFilter, setMeetFilter] = useState("");

    const [error, setError] = useState("");

    const sort = (a: IDualMeet, b: IDualMeet) => b.startedAt! - a.startedAt!;

    const handleDualMeets = async (v: DBResult<Record<string, IDualMeet_DB>>) => {
        if (v.status === "fail") return setError(v.data);
        const values = Object.values(v.data).sort(sort);
        setDualMeetsRaw(values);
        setLoading(false);
        console.timeEnd("getting meets");
    };

    const dualMeets = dualMeetsRaw.filter(l => userCanSeeMeet(l, userInfo));

    useEffect(() => {
        setLoading(true);
        console.time("getting meets");
        DB.getDualMeetsRaw(handleDualMeets);

        return () => {
            DB.stopListeningDualMeetsRaw(handleDualMeets);
        };
    }, []);

    let dualMeetDays: (IDualMeet | IDualMeet_DB)[][] = [];
    const dualMeetDaysObj: Record<string, (IDualMeet | IDualMeet_DB)[]> = {};

    const filteredMeets = dualMeets
        .filter(l => l.season === selectedSeason)
        .filter(l => l.name.toLowerCase().includes(meetFilter.toLowerCase()))
        .filter(
            l =>
                (viewMs && l.type === DualMeetType.MiddleSchool) ||
                (viewHs && (l.type === DualMeetType.Varsity || l.type === DualMeetType.JV || l.type === DualMeetType.Scrimmage))
        )
        .filter(l => viewUnpublished || l.published);

    for (const meet of filteredMeets) {
        const d = new Date(meet.startedAt).toDateString();
        if (d in dualMeetDaysObj) {
            dualMeetDaysObj[d].push(meet);
        } else {
            dualMeetDaysObj[d] = [meet];
        }
        dualMeetDays = Object.values(dualMeetDaysObj);
        dualMeetDays.sort((a, b) => b[0].startedAt - a[0].startedAt);
    }

    return (
        <TbTPage>
            <div style={{ height: 50 }}></div>
            <Typography fontFamily="Lexend Deca" component="h1" variant="h3">
                Dual Meets
            </Typography>
            <Card sx={{ display: "inline-block", margin: "10px auto 20px auto" }}>
                <CardContent>
                    <Typography variant="h5">Search</Typography>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginTop: "15px"
                        }}
                    >
                        <TextField
                            label="Search by name"
                            placeholder="Enter a meet name..."
                            variant="outlined"
                            sx={{ marginRight: "10px" }}
                            value={meetFilter}
                            onChange={e => setMeetFilter(e.target.value)}
                        />
                        <StyledDropdown displayText={`${selectedSeason} season`}>
                            <MenuItem onClick={() => setSelectedSeason("2022-2023")}>2022-2023</MenuItem>
                            <MenuItem onClick={() => setSelectedSeason("2023-2024")}>2023-2024</MenuItem>
                            <MenuItem onClick={() => setSelectedSeason("2024-2025")}>2024-2025</MenuItem>
                        </StyledDropdown>
                    </div>
                </CardContent>
                <List dense sx={{ width: "100%" }}>
                    <ListItemButton onClick={() => setViewHs(u => !u)}>
                        <ListItemIcon>
                            <Checkbox
                                edge="start"
                                checked={viewHs}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ "aria-labelledby": "filterHs" }}
                            />
                        </ListItemIcon>
                        <ListItemText id="filterHs" primary={"View high school meets"} />
                    </ListItemButton>
                    <ListItemButton onClick={() => setViewMs(u => !u)}>
                        <ListItemIcon>
                            <Checkbox
                                edge="start"
                                checked={viewMs}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ "aria-labelledby": "filterMs" }}
                            />
                        </ListItemIcon>
                        <ListItemText id="filterMs" primary={"View middle school meets"} />
                    </ListItemButton>
                    {siteAdmin && (
                        <ListItemButton onClick={() => setViewUnpublished(u => !u)}>
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={viewUnpublished}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ "aria-labelledby": "filterUnpublished" }}
                                />
                            </ListItemIcon>
                            <ListItemText id="filterUnpublished" primary={"View unpublished meets"} />
                        </ListItemButton>
                    )}
                </List>
            </Card>
            {loading ? (
                <CommonLoading />
            ) : (
                <Stack spacing={2}>
                    {dualMeetDays.map(day => (
                        <div key={`dfi${day[0].startedAt.toLocaleString()}`}>
                            <Grid
                                key={`dafioj${day[0].startedAt.toLocaleString()}`}
                                container
                                spacing={3}
                                style={{
                                    width: "90vw",
                                    margin: "auto",
                                    paddingRight: "24px"
                                }}
                            >
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Typography
                                        key={`asdfij${day[0].startedAt.toLocaleString()}`}
                                        fontFamily={"Lexend Deca"}
                                        variant="h4"
                                        textAlign="left"
                                    >
                                        {new Date(day[0].startedAt).toDateString()}
                                    </Typography>
                                </Grid>
                                {day
                                    .filter(l => l.season === selectedSeason)
                                    .map(l => (
                                        <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={`gridItem${l.name}${l.createdAt}`}>
                                            <DualMeetInfoComponent data={l} key={`dualMeetListBox${l.id}`} />
                                        </Grid>
                                    ))}
                            </Grid>
                        </div>
                    ))}
                </Stack>
            )}
        </TbTPage>
    );
}
